<div class="filter-sort-container d-flex align-items-center" [class.active]="value?.length > 0 || subValue?.length > 0">
  <div class='d-flex pointer'>
    <i class="sort-icon" [class]="enableSort?  'icon sm ' + sortedClass():''" (click)="switchSortOrder($event)" data-id="Sort Icon"></i>
  </div>
  @if (enableFilter) {
    <div
      (click)="enableSort && switchSortOrder($event)"
      [class.pointer]="enableSort"
      [smTooltip]="tooltip ? header : undefined"
      class="ellipsis menu-button"
    [class.active]="isSorted || isFiltered()">{{header}}</div>
    <sm-menu
      smMenuClass="light-theme"
      searchPlaceholder="Search"
      [enableSearch]="enableSearch"
      [fixedOptionsSubheader]="subOptions?.length ? fixedOptionsSubheader : ''"
      [searchValue]="searchValue"
      [buttonClass]="'table-filter-button ' + (isFiltered() ? 'active' : 'deactive')"
      [iconClass]="'pointer icon ' + (isFiltered()? 'i-filter-on':'i-filter-off')"
      [hasButtonClass]="false"
      (searchValueChanged)="searching = true; searchValueChanged.emit({value: $any($event.target).value})"
      (menuClosed)="onMenuClose()"
      (menuOpened)="onMenuOpen()"
      >
      @switch (filterType) {
        @case (FILTER_TYPE.duration) {
          <sm-table-filter-duration
            [value]="value"
          (filterChanged)="filterChanged.emit($event)"></sm-table-filter-duration>
        }
        @case (FILTER_TYPE.durationNumeric) {
          <sm-table-filter-duration-numeric
            [value]="value"
            (filterChanged)="filterChanged.emit($event)"
          ></sm-table-filter-duration-numeric>
        }
        @case (FILTER_TYPE.durationDate) {
          <sm-table-filter-duration-date-time
            [value]="value"
            (filterChanged)="filterChanged.emit($event)"
          ></sm-table-filter-duration-date-time>
        }
        @default {
          @if (supportAndFilter) {
            <div class="filter-type" smClickStopPropagation>
              <span data-id="AnyTagOption" class="link me-3" [class.selected]="!andFilter" (click)="toggleCombination()">Any</span>
              <span data-id="allTagOption" class="link" [class.selected]="andFilter" (click)="toggleCombination()">All</span>
            </div>
          }
          <div [class.options-section]="enableSearch" smClickStopPropagation class="d-flex align-items-start flex-column table-filter-sort-template checkbox-list">
            <sm-checkbox-three-state-list
              [supportExcludeFilter]="supportExcludeFilter"
              [options]="options"
              [checkedList]="value"
              (filterChanged)="emitFilterChanged($event)"
            ></sm-checkbox-three-state-list>
            @if (options !== null && filterPageSize && !noMoreOptions && !searching) {
              <sm-dots-load-more
                [loading]="loading" (loadMore)="loadMore()"
                class="mx-auto my-2"
              ></sm-dots-load-more>
            }
            <div fixedOptions>
              @for (option of subOptions; track option.label) {
                <sm-menu-item
                  [itemLabel]="option.label"
                  [checked]="subValue?.includes(option.value)" [selectable]="true" [itemValue]="option.value"
                  (itemClicked)="onSubFilterChanged($event)"
                ></sm-menu-item>
              }
            </div>
          </div>
        }
      }
      @if (columnExplain && options?.length) {
        <div class="column-explanation">{{columnExplain}}</div>
      }
    </sm-menu>
  } @else {
    <div [smTooltip]="tooltip ? header : undefined"
      class="menu-button pointer"
      [class.active]="isSorted"
      (click)="enableSort && switchSortOrder($event)">{{header}}
    </div>
  }
</div>
